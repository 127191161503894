<template>
  <div id="news">
    <div class="NewsInfo">
      <b-container class="show">
        <h4
          class="NewsTitle"
          :class="{'adtcolor' : develop == 'adt'}"
        >
          News
        </h4>
        <b-row>
          <b-col class="searchBox" lg="4" md='3' cols='12'>
            <b-form-select
              class="findNews"
              v-on:change="getSelectedItem"
              v-model="selected"
              :options="options"
              placeholder="Select Month"
            ></b-form-select>
            <!-- <b-form-input v-on:keyup.enter="onEnter" v-model="text" placeholder="Search" class="findNews"></b-form-input> -->
          </b-col>
          <b-col lg='8' md='12' cols='12' class="Searchbox">
            <span class="FilterYear">
              <div class="outerInput">
                 <b-form-input v-on:keyup.enter="onEnter" v-model="text" placeholder="Search" class="findNews"></b-form-input>
                  <div class="imagetest">
                    <img class="searchMag" src="https://assets.asiantour.com/asian-tour/2023/12/Icon_feather-search.png">
                  </div>
                <!-- <font-awesome-icon :icon="['fa', 'magnifying-glass']" /> -->
                <!-- <b-form-input class="Findatourn" id="filter-input" name="gsearch" v-model="filter" placeholder="Find a Tournament"></b-form-input> -->
              </div>
            </span>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <template v-if="loading !== true">
      <div class="loader"></div>
    </template>

    <b-container v-if="loading === true">
      <b-row class="rowNews-Block">
        <template v-for="(postCard, index) in displayedPosts">
          <b-col
            class="col-lg-4 col-md-6 col-12 newsDesktop"
            :key="index"
            v-if="filter"
            :filter="filter"
          > 
            <div class="outerBackimage" >
              <template v-if="develop == 'adt'">
                <b-link :to="'/adt/blog/' + postCard.slug" class="" :class="{'adtcolorlink' : develop == 'adt'}">
                  <div class="outerImage" v-bind:style="{ 'background-image': 'url(' + postCard.better_featured_image.source_url + ')' }">
                  </div>
                </b-link>
              </template>
              <template v-else>
                <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                  <div class="outerImage" v-bind:style="{ 'background-image': 'url(' + postCard.better_featured_image.source_url + ')' }">
                  </div>
                </b-link>
              </template>
              <div class="gradHome" :class="{'gradHomeadt' : develop == 'adt'}"></div>
            </div>
            <div class="newsTitle">News</div>
            <template v-if="develop == 'adt'">
              <b-link :to="'/adt/blog/' + postCard.slug" class="" :class="{'adtcolorlink' : develop == 'adt'}">
                <div class="newsHeading" :class="{'adtcolor' : develop == 'adt'}" v-html="postCard.title.rendered"></div>
              </b-link>
            </template>
            <template v-else>
              <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
            </template>
            <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
          </b-col>
        </template>
      </b-row>

      <transition name="slide-fade">
        <b-row class="rowNewsExtra" v-if="showmore">
          <template v-for="(postCard, index) in posts.slice(9,18)">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-if="filter"
              :filter="filter"
            > 
              <div class="outerBackimage" >
                <div class="outerImage" v-bind:style="{ 'background-image': 'url(' + postCard.better_featured_image.source_url + ')' }">
                </div>
                <div class="gradHome" :class="{'gradHomeadt' : develop == 'adt'}"></div>
              </div>
              <div class="newsTitle">News</div>
              <template v-if="develop == 'adt'">
                <b-link :to="'/adt/blog/' + postCard.slug" class="" :class="{'adtcolorlink' : develop == 'adt'}">
                  <div class="newsHeading" :class="{'adtcolor' : develop == 'adt'}" v-html="postCard.title.rendered"></div>
                </b-link>
              </template>
              <template v-else>
                <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                  <div class="newsHeading" v-html="postCard.title.rendered"></div>
                </b-link>
              </template>
              <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </template>
        </b-row>
      </transition>
      <transition name="slide-fade">
        <b-row class="rowNewsExtra" v-if="showmoretwo">
          <template v-for="(postCard, index) in posts.slice(18,25)">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-if="filter"
              :filter="filter"
            > 
              <div class="outerBackimage" >
                <div class="outerImage" v-bind:style="{ 'background-image': 'url(' + postCard.better_featured_image.source_url + ')' }">
                </div>
                <div class="gradHome" :class="{'gradHomeadt' : develop == 'adt'}"></div>
              </div>
              <div class="newsTitle">News</div>
              <template v-if="develop == 'adt'">
                <b-link :to="'/adt/blog/' + postCard.slug" class="newsLink" :class="{'adtcolorlink' : develop == 'adt'}">
                  <div class="newsHeading" :class="{'adtcolor' : develop == 'adt'}" v-html="postCard.title.rendered"></div>
                </b-link>
              </template>
              <template v-else>
                <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                  <div class="newsHeading" v-html="postCard.title.rendered"></div>
                </b-link>
              </template>
              <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </template>
        </b-row>
      </transition>

      <!-- <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link pagenation" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <template v-if="isMobile(true)">
            <li class="page-item">
              <button :class="{activepage : page == pageNumber}" type="button" class="page-link pagenation" v-for="(pageNumber, index) in pages.slice(page-1, page+2)" @click="page = pageNumber" :key="index"> {{pageNumber}} </button>
            </li>
          </template>
          <template v-else>
            <li class="page-item">
              <button :class="{activepage : page == pageNumber}" type="button" class="page-link pagenation" v-for="(pageNumber, index) in pages" @click="page = pageNumber" :key="index"> {{pageNumber}} </button>
            </li>
          </template>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link pagenation"> Next </button>
          </li>
        </ul>
      </nav>	 -->
      <template v-if="showmore == false">
          <div class="SeeMoreNews" :class="{'SeeMoreNewsADT' : develop == 'adt'}" v-hide="showmore == true" @click="getNextNews()">See More News</div>
      </template>
      <template v-else-if="showmoretwo == false">
        <div class="SeeMoreNews" :class="{'SeeMoreNewsADT' : develop == 'adt'}" v-hide="showmoretwo == true" @click="getNextNewssecond()">See More News</div>
      </template>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "posts",
  props: ["develop", 'config'],
  components: {
  },
  data() {
    return {
      title: '',
      description: '',
      filter: [],
      filterOn: [],
      posts: [],
      isHidden: true,
      text: "",
			page: 1,
			perPage: 9,
			pages: [],	
      loading:'',
      selected: "Select Month",
      options: [],
      monthStart: null,
      monthEnd: null,
      selectedMonth: 0, // Default to All
      value: "",
      filterType: "",
      monthValue: "",
      showmore: false,
      showmoretwo: false
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    setDev: function() {
      if (this.develop == 'adt') {
         return process.env.VUE_APP_WP_ADT_API_URL+"wp/v2/posts?_embed";
      } else {
        return process.env.VUE_APP_WP_ASIAN_TOUR_API_URL+"wp/v2/posts?_embed&randomadd=" + new Date().getTime(); 
      }
    },
		displayedPosts () {
			return this.paginate(this.posts);
		},
    filteredPosts() {
      return this.$options.filters.filterByMonth(
        this.posts,
        this.selectedMonth
      );
    }
  },
  methods: {
    getSelectedItem: function() {
      this.monthValue = this.selected;
      console.log("this.monthValue");
      console.log(this.monthValue);
      if (this.monthValue == "Select Month") {
        return this.getNews();
      }
      const year = moment(this.monthValue).year();
      const month = moment(this.monthValue).month();

      this.monthStart = moment()
        .set({ year: year, month: month })
        .startOf("month")
        .toISOString();
      this.monthEnd = moment()
        .set({ year: year, month: month })
        .endOf("month")
        .toISOString();
      this.getNews(true);
    },
    createMonths() {
      const getMonths = (start, end) =>
        Array.from({ length: end.diff(start, "month") + 1 }).map((_, index) =>
          moment(start)
            .add(index, "month")
            .format("MMMM YYYY")
        );
      const end_of_month = moment()
        .endOf("month")
        .format("MM.YYYY");
      const months = getMonths(
        moment("01.2019", "MM.YYYY"),
        moment(end_of_month, "MM.YYYY")
      );
      this.options = months.reverse();
      this.options.unshift("Select Month");
    },
    getNewsDate: function() {
      // let arr = [];
      this.posts.forEach(value => {
        this.getSelectedItem();
        // value.date_gmt
        var jsonDate = value.date;
        jsonDate = jsonDate.replace(/^.{5}/g, "");
        jsonDate = jsonDate.slice(0, -12);
        var dateStr = JSON.stringify(jsonDate);
        console.log("dateStr");
        console.log(dateStr);
        if (dateStr == this.monthValue) {
          this.showArticle == "Y";
        } else {
          this.showArticle == "N";
        }
      });
    },
    isMobile() {
      if( screen.width <= 760 ) {
        return true;
      }
      else {
        return false;
      }
    },
		setPages () {
			let numberOfPages = Math.ceil(this.posts.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index);
			}
		},
		paginate (posts) {
			let page = this.page;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
			return  posts.slice(from, to);
		},
    onEnter: function() {
       window.location.href = '/search?search='+ this.text + '&develop=' + this.develop;
    },
    getNews(month_filter) {
      let news_request = this.setDev + "?page=10&per_page=100";
      if (month_filter === true) {
        console.log(month_filter);
        news_request +=
          "&after=" + this.monthStart + "&before=" + this.monthEnd;
      }
      axios
        .get(news_request)
        .then(
          response => ((this.posts = response.data), (this.loading = true))
        );
    },
    getNextNews: function() {
      this.showmore = true;
      // index = 2 * 2;
      // console.log("index")
      // console.log(index)
      axios
        .get('https://wp-asiantour.ocs-sport.com/index.php/wp-json/wp/v2/posts?per_page=100')
        // .get('https://wp-asiantour.ocs-sport.com/index.php/wp-json/wp/v2/posts?')
        .then(response => (
          this.posts = response.data,
          this.loading = true
        ));
    },
    getNextNewssecond: function() {
      this.showmoretwo = true;
      // index = 2 * 2;
      // console.log("index")
      // console.log(index)
      axios
        .get('https://wp-asiantour.ocs-sport.com/index.php/wp-json/wp/v2/posts?per_page=100')
        // .get('https://wp-asiantour.ocs-sport.com/index.php/wp-json/wp/v2/posts?')
        .then(response => (
          this.posts = response.data,
          this.loading = true
        ));
    }
  },
	watch: {
		posts () {
			this.setPages();
		}
	},
	created(){
		this.getPosts();
	},
  mounted() {
    this.getNews();
    this.createMonths();
    setTimeout(() => {
    axios
      .get(this.setDev + "?per_page=12")
      .then(response => (
        this.posts = response.data,
        this.loading = true
      ));},1000)
  }
};
</script>

<style scoped>
::v-deep a.adtcolorlink {
  text-decoration: none;
}
::v-deep .adtcolor {
  color: #76c3bd!important;
}
::v-deep a.adtcolorlink > div:hover{
  color: #1c857c!important;
  text-decoration: none;
}
::v-deep .custom-select {
  display: inline-block;
  width: 100%; 
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  vertical-align: middle;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center/12px 8px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
img.searchMag {
  float: right;
  margin-top: -28px;
  margin-right: 16px;
}
::v-deep .gradHome {
  background-color: #D2682E;
  background-image: linear-gradient(to right, #ffb21c, #D2682E);
  height: 10px;
  width: 97.3%;
  position: absolute;
}
::v-deep .gradHomeadt {
  background-color: #76c3bd;
  background-image: linear-gradient(to right, #76c3bd , #1c857c);
  height: 10px;
  width: 97.3%;
  position: absolute;
}
.outerImage {
  /* max-height: 200px; */
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 275px; */
  height: 230px;
}
.SeeMoreNews {
  width: 324px;
  height: 55px;
  background-color: #0A3F7F;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 4;
  margin: auto;
  margin-top: 50px;
}
.SeeMoreNews:hover {
  width: 324px;
  height: 55px;
  background-color: #5897E6;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 4;
  margin: auto;
  cursor: pointer;
  margin-top: 50px;
}
.SeeMoreNewsADT {
  width: 324px;
  height: 55px;
  background-color: #76c3bd;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 4;
  margin: auto;
  margin-top: 50px;
}
.SeeMoreNewsADT:hover {
  width: 324px;
  height: 55px;
  background-color: #1c857c;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 4;
  margin: auto;
  cursor: pointer;
  margin-top: 50px;
}
::v-deep a.newsLink > div:hover {
  color: #5897E6!important;
  cursor: pointer;
}
::v-deep a.newsLink{
  cursor: pointer;
  text-decoration: none!important;
}
.newsImage {
  width: 100%;
  /* max-height: 200px; */
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.newsContent {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}
.findNews {
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
}
.NewsTitle {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.NewsInfo {
  background-color: #f1f1f1;
}
.rowNews-Block {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 50px;
}
.rowNewsExtra {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 0px;
}
.newsDesktop {
  padding-left: 5px;
  padding-right: 5px;
}
button.page-link.pagenation.activepage {
  background: #263056;
  color: #fff;
}
button.page-link.pagenation:hover {
  background-color: #263056;
  color: #fff;
}
button.page-link {
	display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #133f7b;
  font-weight: 500;
}
.offset{
  width: 500px !important;
  margin: 20px auto;  
}
.itemDate {
  font-size: 10pt;
}
.loader {
  border: 16px solid white; /* Light grey */
  border-top: 16px solid #263056; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.developGreen>.btn{
  background-color: #76c3bd !important;
  border: 1px solid #76c3bd !important;
}
::v-deep button.btn.showMore.btn-secondary {
  color: #fff;
  background-color: #0b3f7e;
  border-color: #253442 ;
}
::v-deep button.btn.showMore.btn-secondary:hover {
  color: #fff;
  background-color: #0b3f7e !important;
  border-color: #253442 !important;
}

::v-deep a.btn.sIcon.btn-secondary {
  border: 1px solid #0b3f7e ;
  border-radius: 0px 5px 5px 0px !important;
  color: #fff !important;
  background: rgb(11 63 126) ;
  font-weight: 400 !important;
  padding: 6px 0px !important;
  display: block !important;
  width: 40px;
  height: 38px;
  margin-top: 0px;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
  color: #fff;
}
.searchBoxbutton {
  margin-left: -30px;
}
.searchBox>input{
  /* border-radius: 5px 0px 0px 5px !important; */
}
.btn-secondary {
  color: #fff;
  background-color: #0b3f7e !important;
  border-color: #253442 !important;
}
span.searchBlock {
  display: flex;
  position: relative;
  top: 1.3em;
}
.divBlock {
  height: 35px;
  background: #133f7b;
  width: 40px;
  border-radius: 0px 10px 10px 0px;
}
#filter-input::-webkit-input-placeholder {
  color: #aaa;
  font-size: 14px;
  padding-left: 10px;
  height: 34px;
  position: relative;
  top: -2px;
}
input#filter-input {
  border: none;
  background-color: #ffffff;
  background-image: none;
  border: 0px solid #283f6f;
  color: #aaaaaa;
  display: inline-block;
  overflow: hidden;
  height: 34px;
  padding: 6px 12px;
  border-radius: 10px 0px 0px 10px;
}
/* ::v-deep .btn-secondary:hover {
  border: 1px solid #0b3f7e!important;
  border-radius: 10px!important;
  color: #fff!important;
  background: rgba(0,0,0,.5)!important;
  font-weight: 400!important;
  padding: 10px 60px!important;
  display: block!important;
} */
button.btn.showMore.btn-secondary {
  background-color: #133f7b;
  margin-bottom: 2em;
}
.newsSlider {
  margin-top: 0.5em !important;
}
#news {
  /* background-color: #f1f1f1; */
  padding-bottom: 5%;
}
.more {
  text-align: center;
}
.show {
  padding-bottom: 5%;
  padding-top: 5%;
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 0px;
  margin-right: 15px;
  margin-bottom: 3em;
}
.card-img-top {
  width: 100%;
  height: auto;
  /* min-height: 183px; */
  /* border-radius: 10px 10px 0 0 !important; */
  max-height: 200px;
  object-fit: cover;
}
.card-body {
  padding: 15px;
  /* min-height: 115px; */
  min-height: 140px;
  background: #fff;
}
.card-footer {
  border-radius: 0 0 10px 10px !important;
  background-color: #133f7b;
  color: white;
  text-align: center;
  padding: 0rem 0;
  border-top: 0px solid rgba(0, 0, 0, 0.125);
}
#title {
  padding-top: 5%;
  padding-bottom: 5%;
}
.card {
  background-color: transparent;
  border: 0;
  /* margin-bottom: 30px !important; */
  margin-bottom: 0.5rem !important;
}
.exre {
  font-size: 12pt;
  color: #b4b4b4;
}
.pt {
  color: #133f7a;
  font-size: 16px;
}
::v-deep .nav-link {
  background-color: #133f7b;
  margin-top: -12%;
  font-size: 1.4vh!important;
  border-radius: 0 0 10px 10px;
}
::v-deep .nav-link:hover {
  color: white;
  background-color: #7aafef;
  margin-top: -11%;
  border-radius: 10px;
}
::v-deep li.nav-item:hover {
  background-color: #7aafef;
  border-radius: 0px 0px 10px 10px;
}
.space {
  padding-bottom: 2%;
  border-bottom: 1px solid lightgrey;
  padding-top: 2%;
}
.developGreenText {
  color: #76c3bd !important;
}
.developGreen > .card-footer {
  background-color: #76c3bd !important;
}
.developGreen > .card-footer > li > .nav-link {
  background-color: #76c3bd !important;
}

::v-deep .nav-link {
  color: #fff !important;
  font-size: 1rem;
}
@media only screen and (max-width: 990px) {
  .searchBoxbutton {
    margin-left: -30px;
  }
  .findNews {
    border-radius: 0;
    border: 0px solid #c7c7cc;
    height: 43px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 769px) {
  .newsContent {
    color: #707070;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) {
  img.searchMag {
    float: right;
    margin-top: -45px;
    margin-right: 16px;
  }
  .outerImage {
    max-height: 100%!important;
    overflow: hidden;
  }
  ::v-deep .nav-link {
    text-align: center;
    margin-top: -6%;
  }
  .card-body {
    padding: 15px;
    min-height: 140px;
    background: #fff;
  }
}

@media only screen and (max-width: 480px) {
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    background-color: #133f7b;
    margin-top: -12%;
    font-size: 1.4vh!important;
    border-radius: 0 0 10px 10px;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
  .card-img-top{
    width: 100%;
    height: auto;
    min-height: 183px;
    border-radius: 10px 10px 0 0 !important;
    max-height: max-content;
  }
  .logo{
    display: none;
  }
  img.searchMag {
    float: right;
    margin-top: -44px;
    margin-right: 16px;
  }
}
</style>